.search-float {
    position: fixed;
    bottom: 20px;
    right: 5%;
    width: 90%;
    font-size: 30px;
    height: 40px;
}


.first-letter {
    position: absolute;
    width: 730px;
    height: 470px;
    left: 0px;
    top: -96px;
}
.second-letter {
    position: absolute;
    width: 730px;
    height: 470px;
    left: 52px;
    top: -94px;
}
.third-letter {
    position: absolute;
    width: 730px;
    height: 470px;
    left: 102px;
    top: -92px;
}
.fourth-letter {
    position: absolute;
    width: 730px;
    height: 470px;
    left: 0px;
    top: 12px;
}
.fifth-letter {
    position: absolute;
    width: 730px;
    height: 470px;
    left: 52px;
    top: 14px;
}
.sixth-letter {
    position: absolute;
    width: 730px;
    height: 470px;
    left: 104px;
    top: 15px;
}




.first-letter-small {
    position: absolute;
    width: 38px;
    height: 107px;
    left: 280px;
    top: 160px;
}
.second-letter-small {
    position: absolute;
    width: 38px;
    height: 105px;
    left: 319px;
    top: 162px;
}
.third-letter-small {
    position: absolute;
    width: 38px;
    height: 102px;
    left: 357px;
    top: 165px;
}
.fourth-letter-small {
    position: absolute;
    width: 38px;
    height: 101px;
    left: 395px;
    top: 166px;
}
.fifth-letter-small {
    position: absolute;
    width: 39px;
    height: 105px;
    left: 280px;
    top: 271px;
}
.sixth-letter-small {
    position: absolute;
    width: 38px;
    height: 105px;
    left: 319px;
    top: 272px;
}
.seventh-letter-small {
    position: absolute;
    width: 38px;
    height: 105px;
    left: 357px;
    top: 273px;
}
.eighth-letter-small {
    position: absolute;
    width: 38px;
    height: 104px;
    left: 395px;
    top: 274px;
}







.blank-pru {
    alignSelf: center;
    object-fit: fill;
    height: 492px;
    width: 720px;
}